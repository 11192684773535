import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import ButtonLink from './links/button-link';
import { mq } from './_shared/media';
import { StyledSection } from './_shared/styled-section';
import Img from 'gatsby-image';
import Icon from './icon';
const StyledHeroSection = styled(StyledSection)`
 // min-height: calc(100vh - 2 * var(--header-height));
  position: relative;
  margin-top: 61px;
  ${mq.gt.sm} {
    margin-top: auto;
   // min-height: calc(60vh - var(--header-height));
  }
`;
const StyledIntroduction = styled.div`
  color: var(--primary-color);
  font-weight: normal;
`;
const StyledAuthor = styled.h1`
  margin-left: -4px !important;
  font-size: 40px;
  line-height: 1.1;
  margin: 0;
  word-break: break-word;

  ${mq.gt.xs} {
    font-size: 80px;
  }
`;
const StyledTagline = styled.h2`
  margin-left: -4px !important;
  font-size: 40px;
  line-height: 1.1;
  margin: 0;
  color: var(--primary-color);
  word-break: break-word;

  ${mq.gt.xs} {
    font-size: 40px;
  }
`;
const StyledDescription = styled.div`
  margin-top: 0.5rem;
  width: 100%;
  //max-width: 500px;
`;
const StyledContacts = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;
  width: 100%;
  margin-top: 2rem;

  ${mq.gt.xs} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mq.gt.sm} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledContainer = styled.section`
  display: flex;
  align-items: center;

  & > svg {
    font-size: 2rem;
    margin-right: 0.75rem;
  }
`;

const StyledFormContainer = styled.section`
  //transform: skewy( 11deg );
      margin-top: 20px;


  & > span {
    font-size: 0.75rem;
    font-weight: 500;
  }
`;
const StyledForm = styled.div`
  color: var(--primary-color);

  font-weight: normal;
`;
const Hero = ({ data }) => {
  const { introduction, author, tagline, description, ctaLink, ctaLabel } = data;

  return (
    <StyledHeroSection>
      <StyledIntroduction>{author}</StyledIntroduction>
      <StyledAuthor>{introduction}</StyledAuthor>
      <StyledTagline>{tagline}</StyledTagline>
      <StyledDescription dangerouslySetInnerHTML={{ __html: description }} />
      {/* <ButtonLink label={ctaLabel} link={ctaLink} /> */}
      <StyledContacts>
         
        
    
     
         <StyledContainer>
           {/* <Icon icon="paper-plane" /> */}
           <StyledFormContainer>
             <StyledForm>E-Mail</StyledForm>
             <span>	hello@buha-office.de</span>
           </StyledFormContainer>
         </StyledContainer>
       

         <StyledContainer>
         {/*   <Icon icon="mobile-alt" /> */}
           <StyledFormContainer>
             <StyledForm>Telefon</StyledForm>
             <span>+49 41652162176</span>
           </StyledFormContainer>
         </StyledContainer>
        
     </StyledContacts>
    </StyledHeroSection>
  );
};

Hero.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Hero;
