import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import About from '../components/about';
import CardGrid from '../components/card-grid';
import Contact from '../components/contact';
import FeaturedProjects from '../components/featured-projects';
import Hero from '../components/hero';
import Facts from '../components/facts';
import Layout from '../components/layout';
import RecentPosts from '../components/recent-posts';
import SEO from '../components/seo';
import { indexMenuLinks } from '../components/_config/menu-links';

const Index = ({ data }) => {
  const heroData = {
    author: data.site.siteMetadata.author,
    tagline: data.hero.frontmatter.tagline,
    description: data.hero.html,
    introduction: data.hero.frontmatter.introduction,
    ctaLabel: data.hero.frontmatter.cta_label,
    ctaLink: data.hero.frontmatter.cta_link,
  };

  return (
    <Layout menuLinks={indexMenuLinks}>
      <SEO title="Home" />
      <Hero data={heroData} />
      <Facts  data={data.about}/>
      <About data={data.uberatung} />
      <Facts  data={data.second}/>
      <About data={data.fberatung} />
      <Facts  data={data.second}/>
      <About data={data.lohn} />
     {/*  <CardGrid cards={data.cards.frontmatter.cards} description={data.cards.html} title="Our Features" id="features" />
      <FeaturedProjects featured={data.featuredProjects.nodes} />
      <RecentPosts data={data.blog.edges} /> */}
      <Contact data={data.contact} />

    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  {
    site {
      siteMetadata {
        author
      }
    }
    hero: markdownRemark(fileAbsolutePath: {regex: "/content/sections/hero/"}) {
      frontmatter {
        introduction
        tagline
        cta_label
        cta_link
      }
      html
    }
    about: markdownRemark(fileAbsolutePath: {regex: "/content/sections/about/"}) {
      frontmatter {
        title
        techs
        id
        about_image {
          absolutePath
          childImageSharp {
            id
            fluid {
              srcSet
              srcSetWebp
              src
            }
            
            gatsbyImageData
          }
          relativePath
          publicURL
        }
      }
      html
    }
    last: markdownRemark(fileAbsolutePath: {regex: "/content/sections/last/"}) {
      frontmatter {
        title
        techs
        id
        about_image {
          absolutePath
          childImageSharp {
            id
            fluid {
              srcSet
              srcSetWebp
              src
            }
            
            gatsbyImageData
          }
          relativePath
          publicURL
        }
      }
      html
    }
    second: markdownRemark(fileAbsolutePath: {regex: "/content/sections/second/"}) {
      frontmatter {
        title
        techs
        id
        about_image {
          absolutePath
          childImageSharp {
            id
            fluid {
              srcSet
              srcSetWebp
              src
            }
            
            gatsbyImageData
          }
          relativePath
          publicURL
        }
      }
      html
    }
    uberatung: markdownRemark(fileAbsolutePath: {regex: "/content/sections/uberatung/"}) {
      frontmatter {
        title
        techs
        id
        about_image {
          absolutePath
          childImageSharp {
            id
            fluid {
              srcSet
              srcSetWebp
              src
            }
            
            gatsbyImageData
          }
          relativePath
          publicURL
        }
      }
      html
    }
    fberatung: markdownRemark(fileAbsolutePath: {regex: "/content/sections/fberatung/"}) {
      frontmatter {
        title
        techs
        id
        about_image {
          absolutePath
          childImageSharp {
            id
            fluid {
              srcSet
              srcSetWebp
              src
            }
            
            gatsbyImageData
          }
          relativePath
          publicURL
        }
      }
      html
    }
    lohn: markdownRemark(fileAbsolutePath: {regex: "/content/sections/lohn/"}) {
      frontmatter {
        title
        techs
        id
        about_image {
          absolutePath
          childImageSharp {
            id
            fluid {
              srcSet
              srcSetWebp
              src
            }
            
            gatsbyImageData
          }
          relativePath
          publicURL
        }
      }
      html
    }
 
    contact: markdownRemark(fileAbsolutePath: {regex: "/content/sections/contact/"}) {
      frontmatter {
        phone
        email
        address
      }
      html
    }
  }
`;
