/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-undef */
import styled from '@emotion/styled';
import React from 'react';
//import StyledSkewedSection from './skewed-section';
import TechList from './tech-list';
import { StyledH1, StyledH2 } from './_shared/styled-headings';
import { StyledStaticImageContainer } from './_shared/styled-image-container';
import { StyledSection } from './_shared/styled-section';
import Img from 'gatsby-image';
import { mq } from './_shared/media';
import ScrollIndicator from './scroll-indicator';
import Icon from './icon';


const StyledLogoSection = styled.section`
  width: 100%;
  display: flex;
  justify-content:center;
  flex-wrap: wrap;
`;

const StyledFactNumber = styled.section`
  display: flex;
  flex-direction: column;
  padding: 1rem;

  & > .number {
    margin-left: 2rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: bold;
  }

  & > .fact {
    position: relative;
    text-transform: uppercase;
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
   // background-color: var(--bg-color);
    margin-left: 2rem;
/* 
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -2rem;
      width: 1rem;
      height: 2px;
      background-color: var(--primary-color);
      background-size: cover;
      background-image: url(http://buha-office.de/img/FR1.png);
    } */
  }
`;

const StyledTextSection = styled.section`
  white-space: pre-line;
`;

export const ImgSkew = styled.div`
position: relative;
height: 33vh;
width: 100%;
left: 0;
top: 0;
right: 0;
bottom: 0;
z-index: 2;
/* -webkit-transform: skewy(  -11deg );
-ms-transform: skewy(-11deg);
transform: skewy(  -11deg ); */
-webkit-transform-origin: 50% 0;
-ms-transform-origin: ;
transform-origin: 50% 0;
outline: 1px solid transparent;
margin-top: 0px;
    top: 0;
    bottom: 0;
    img{
      height: 100%;
      object-fit: cover;
    }
    ${mq.gt.sm} {
     height: auto;
    }
`;
const StyledContacts = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;
  width: 100%;
  margin-top: 2rem;

  ${mq.gt.xs} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mq.gt.sm} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledContainer = styled.section`
  display: flex;
  align-items: center;

  & > svg {
    font-size: 2rem;
    margin-right: 0.75rem;
  }
`;

const StyledFormContainer = styled.section`
  //transform: skewy( 11deg );
      margin-top: 20px;


  & > span {
    font-size: 0.75rem;
    font-weight: 500;
  }
`;
const StyledForm = styled.div`
  color: var(--primary-color);

  font-weight: normal;
`;
const Facts = ({data}) => {
  const {
    frontmatter: { title, techs, about_image },
    html,
  } = data;


  const image = about_image ? about_image.childImageSharp.fluid : null;
  console.log(about_image, about_image.childImageSharp.fluid)
  return (
    <section angle={10} >
      <ImgSkew>
      {image && (
           <img src={about_image.publicURL} objectFit="contain"  className="section-image" width="100%" />
       
        )}

        </ImgSkew>
   {/*   <div>
          <StyledH2>{title}</StyledH2>
          <StyledTextSection dangerouslySetInnerHTML={{ __html: html }} />
          <TechList techs={techs} />
        </div>
      <StyledH1>Simone Grau.</StyledH1>
      <StyledLogoSection> */}
    {/*  <StyledFactNumber>
           <span className="fact">hello@buha-office.de</span>
        </StyledFactNumber>
        <StyledFactNumber>
          <span className="fact">+49 172 3188451</span>
        </StyledFactNumber>
      <StyledFactNumber>
          <span className="fact">Freelancing Hours</span>
        </StyledFactNumber> 
      </StyledLogoSection> */}
       <ScrollIndicator />
    </section>
  );
};

export default Facts;
